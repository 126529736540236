import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`What We Do`}</h2>
    <ProjectCard title="Surveillance Cameras" link="#" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  Keep an eye on your business and keep up on security with leading tech
  including IP cameras, ai driven recording, and 4K and beyond image quality.
    </ProjectCard>
    <ProjectCard title="Wireless Upgrades" link="#" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  Need to update dated wireless technology? Expand reach for customers and
  employees? Or starting from scratch, we build data driven wireless
  installations from the ground up.
    </ProjectCard>
    <ProjectCard title="Networking" link="#" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  From cabeling to servers, we have the tools and experience to build out a new
  location, or update an existing one.
    </ProjectCard>
    <ProjectCard title="IP Phone Systems" link="#" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  With Voice Over IP (VOIP) phone systems, integrating your number one form of
  communications into your CRM systems will elevate your business to a whole new
  level.
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      