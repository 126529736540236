import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About Us`}</h2>
    <p>{`With over 15 years of experience, Helic Digital has the tools and skills to execute on all stacks of you IT infrastructure. We specialize in networking, surveillance cameras, and IP phone systems. Whether your project is big or small, we will devote our years of experience to providing you the best solution to fit your business.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      