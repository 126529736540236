import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Reach Out`}</h2>
    <p>{`Say `}<a parentName="p" {...{
        "href": "mailto:anthony@helicdigital.com"
      }}>{`Hi`}</a>{` and let's get started on setting up your business with leading technology to keep you at the forefront of your field. `}<a parentName="p" {...{
        "href": "mailto:anthony@helicdigital.com"
      }}>{`anthony@helicdigital.com`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      